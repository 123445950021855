/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable global-require */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Form from './component/Form';

function NotFound(props) {
  return (
    <div className="container">
      <div className="d-flex justify-content-center align-items-center flex-column p-3" style={{ height: '100vh' }}>
        <img src={require('./img/warning.gif')} className="errorImage align-self-center" alt="" />
        <p className="text-dark h1 text-center" style={{ marginTop: '-1em' }}>404</p>
        <h4 className="text-center">{props.errorMessage}</h4>
        <a
          href="https://mpstore.co.id"
          className="text-decoration-none text-info font-weight-bold text-center align-self-center h5"
          rel="noreferrer"
        >
          &larr; Kembali Ke Halaman Utama
        </a>
      </div>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:ref" element={<Form />} />
        <Route path="*" element={<NotFound errorMessage="Data Tidak Ditemukan" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
