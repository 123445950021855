/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable global-require */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import Tabs from 'react-tabs/lib/components/Tabs';
import TabList from 'react-tabs/lib/components/TabList';
import Tab from 'react-tabs/lib/components/Tab';
import TabPanel from 'react-tabs/lib/components/TabPanel';
import axios from 'axios';
// import localStorage from 'local-storage';
import { useParams } from 'react-router-dom';
import { DetailUpline } from './DetailUpline';
import { ListHarga } from './ListHarga';
import {
  getToken, getApiWithKey, errorHandler,
} from '../api/api';

function Form() {
  const params = useParams();

  const [warning, setWarning] = useState({
    show: false,
    message: '',
    code: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKota, setListKota] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [namaLengkap, setNamaLengkap] = useState('');
  const [namaToko, setNamaToko] = useState('');
  const [nomorTelepon, setNomorTelepon] = useState('');
  const [email, setEmail] = useState('');
  const [provinsi, setProvinsi] = useState('');
  const [kota, setKota] = useState('');
  const [kecamatan, setKecamatan] = useState('');
  const [alamat, setAlamat] = useState('');
  const [kodePos, setKodePos] = useState('');
  const [errors, setErrors] = useState({});
  const [pulsa, setPulsa] = useState([]);
  const [paketData, setPaketData] = useState([]);
  const [game, setGame] = useState([]);
  const [topupSaldo, setTopupSaldo] = useState([]);
  const [ppob, setPpob] = useState([]);
  const [tokenPln, setTokenPln] = useState([]);
  const [dataUpline, setDataUpline] = useState({
    nama: '',
    namatoko: '',
    phone: '',
    alamat: '',
  });

  // eslint-disable-next-line prefer-const
  let inputRef = {
    namaLengkap: undefined,
    namaToko: undefined,
    nomorTelepon: undefined,
    email: undefined,
    provinsi: undefined,
    kota: undefined,
    kecamatan: undefined,
    kodePos: undefined,
    alamat: undefined,
  };

  // API
  const getProvinsi = () => {
    axios.get('/api-wilayah/provinces.json')
      .then((response) => {
      // handle success
        setListProvinsi(response.data);
      })
      .catch((error) => {
      // handle error
      });
  };

  const getKota = (provinsiId) => {
    axios.get(`/api-wilayah/regencies/${provinsiId}.json`)
      .then((response) => {
      // handle success
        setListKota(response.data);
      })
      .catch((error) => {
      // handle error
      });
  };

  const getKecamatan = (kotaId) => {
    axios.get(`/api-wilayah/districts/${kotaId}.json`)
      .then((response) => {
      // handle success
        setListKecamatan(response.data);
      })
      .catch((error) => {
      // handle error
      });
  };

  const getDataUpline = async (token) => {
    const res = await getApiWithKey('/profile', 'get', token);
    // console.log(res);
    if (res.status === 200) {
      setDataUpline(res.values);
    } else {
      setWarning(await errorHandler(res));
    }
  };

  const getHargaPulsa = async (token) => {
    const res = await getApiWithKey('/pricelist/pulsa', 'get', token);
    if (res.status === 200) {
      setPulsa(res.values.pricelists);
    } else {
      setWarning(await errorHandler(res));
    }
  };

  const getHargaPaketData = async (token) => {
    const res = await getApiWithKey('/pricelist/data', 'get', token);
    if (res.status === 200) {
      setPaketData(res.values.pricelists);
    } else {
      setWarning(await errorHandler(res));
    }
  };

  const getHargaGame = async (token) => {
    const res = await getApiWithKey('/pricelist/game', 'get', token);
    if (res.status === 200) {
      setGame(res.values.pricelists);
    } else {
      setWarning(await errorHandler(res));
    }
  };

  const getHargaTopupSaldo = async (token) => {
    const res = await getApiWithKey('/pricelist/topup', 'get', token);
    if (res.status === 200) {
      setTopupSaldo(res.values.pricelists);
    } else {
      setWarning(await errorHandler(res));
    }
  };

  const getHargaPpob = async (token) => {
    const res = await getApiWithKey('/pricelist/ppob', 'get', token);
    if (res.status === 200) {
      setPpob(res.values.pricelists);
    } else {
      setWarning(await errorHandler(res));
    }
  };

  const getHargaTokenPln = async (token) => {
    const res = await getApiWithKey('/pricelist/token', 'get', token);
    if (res.status === 200) {
      setTokenPln(res.values.pricelists);
    } else {
      setWarning(await errorHandler(res));
    }
  };

  const fetchData = async () => {
    const res = await getToken(params.ref);
    // console.log(res);
    if (res.status === 404) {
      setWarning({ message: 'Data Tidak Ditemukan!', code: 404, show: true });
      setIsLoading(false);
    } else if (res.status === 200) {
      Promise.all([
        getDataUpline(res.values.token),
        getProvinsi(res.values.token),
        getHargaPulsa(res.values.token),
        getHargaPaketData(res.values.token),
        getHargaGame(res.values.token),
        getHargaTopupSaldo(res.values.token),
        getHargaPpob(res.values.token),
        getHargaTokenPln(res.values.token),
      ]).then(() => {
        setIsLoading(false);
      });
    } else {
      setWarning({ message: res.message, code: res.statusCode, show: true });
      setIsLoading(false);
    }
    // }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  // onChanges Select Option
  const onChangeProvinsi = (e) => {
    setProvinsi(e.target.value);
    getKota(listProvinsi.filter((item) => item.name === e.target.value)[0].id);
    setListKecamatan([]);
  };

  // onChanges Select Option
  const onChangeKota = (e) => {
    setKota(e.target.value);
    getKecamatan(listKota.filter((item) => item.name === e.target.value)[0].id);
  };

  //   validation
  const validation = () => {
    // eslint-disable-next-line prefer-const
    let error = {};
    let formIsValid = true;

    // Nama
    if (namaLengkap === '') {
      error.namaLengkap = 'Nama tidak boleh kosong';
      formIsValid = false;
      inputRef.namaLengkap.focus();
    } else if (!/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(namaLengkap)) {
      formIsValid = false;
      error.namaLengkap = 'Hanya huruf';
      inputRef.namaLengkap.focus();
    }

    // Nama Toko
    // if (namaToko === '') {
    //   error.namaToko = 'Nama toko tidak boleh kosong';
    //   formIsValid = false;
    //   inputRef.namaToko.focus();
    // }

    // Nomor Telepon
    if (nomorTelepon === '') {
      error.nomorTelepon = 'Nomor telepon tidak boleh kosong';
      formIsValid = false;
      inputRef.nomorTelepon.focus();
    } else if (!/^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/.test(nomorTelepon)) {
      formIsValid = false;
      error.nomorTelepon = 'Masukkan nomor telepon dengan benar';
      inputRef.nomorTelepon.focus();
    }

    // eslint-disable-next-line no-useless-escape
    const emailRe = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    // Email
    if (email === '') {
      error.email = 'Email tidak boleh kosong';
      formIsValid = false;
      inputRef.email.focus();
    } else if (!emailRe.test(email)) {
      formIsValid = false;
      error.email = 'Masukkan format email dengan benar';
      inputRef.email.focus();
    }

    // Provinsi
    if (provinsi === '' || provinsi === 'Pilih...') {
      error.provinsi = 'Silahkan pilih provinsi';
      formIsValid = false;
      inputRef.provinsi.focus();
    }

    // Kecamatan
    if (kecamatan === '' || kecamatan === 'Pilih...') {
      error.kecamatan = 'Silahkan pilih kecamatan';
      formIsValid = false;
      inputRef.kecamatan.focus();
    }

    // Kota
    if (kota === '' || kota === 'Pilih...') {
      error.kota = 'Silahkan pilih kota';
      formIsValid = false;
      inputRef.kota.focus();
    }

    // Kode Pos
    if (kodePos === '') {
      error.kodePos = 'Kode Pos tidak boleh kosong';
      formIsValid = false;
      inputRef.kodePos.focus();
    } else if (!/^([1-9])[0-9]{4}$/.test(kodePos)) {
      formIsValid = false;
      error.kodePos = 'Masukkan kode pos dengan benar';
      inputRef.kodePos.focus();
    }

    // Alamat
    if (alamat === '') {
      error.alamat = 'Alamat tidak boleh kosong';
      formIsValid = false;
      inputRef.alamat.focus();
    } else if (alamat.length <= 5) {
      error.alamat = 'Alamat tidak boleh kurang dari 5 karakter';
      formIsValid = false;
      inputRef.alamat.focus();
    }

    setErrors(error);
    if (formIsValid) {
      const isiPesan = `*DAFTAR MENJADI AGEN MPStore*%0a%0aSelamat Pagi/Siang/Sore/Malam Bapak/Ibu. Saya dengan data sebagai berikut: %0aNama Lengkap : ${namaLengkap} %0aNama Toko : ${namaToko === '' ? '-' : namaToko} %0aNomor Telepon : ${nomorTelepon} %0aEmail : ${email} %0aProvinsi : ${provinsi} %0aKota : ${kota} %0aKecamatan : ${kecamatan} %0aKode Pos : ${kodePos} %0aAlamat : ${alamat}%0a%0aIngin mendaftar menjadi agen MPStore. Terimakasih`;
      window.open(`https://wa.me/${dataUpline.phone.replace(/^0/, 62)}?text=${isiPesan}`);
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ErrorComponentTransparent(props) {
    if (props.show) {
      return (
        <div className="overlay d-flex justify-content-center align-items-center flex-column p-3">
          <div className="justify-content-center align-items-center flex-column bg-white p-4 rounded text-center">
            <img src={require('../img/warning.gif')} className="errorImage align-self-center" alt="" />
            {/* <Warning className="errorImage" /> */}
            {props.code && (
              <p className="text-dark h1 text-center" style={{ marginTop: '-1em' }}>{props.code}</p>
            )}
            {props.title && (
              <p className="text-dark h5 text-center">{props.title}</p>
            )}
            <a
              href="https://mpstore.co.id"
              className="text-decoration-none text-info font-weight-bold text-center align-self-center h5"
              rel="noreferrer"
            >
              &larr; Kembali Ke Halaman Utama
            </a>
          </div>
        </div>
      );
    }
    return null;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function LoadingScreen(props) {
    if (props.show) {
      return (
        <div className="overlay d-flex justify-content-center align-items-center flex-column p-3">
          <img src={require('../img/loading.gif')} className="errorImage2 align-self-center" alt="" />
        </div>
      );
    }
    return null;
  }

  return (
    <>
      <LoadingScreen show={isLoading} />
      <ErrorComponentTransparent show={warning.show} title={warning.message} code={warning.code} />
      <div
        className="container-fluid bg-dark text-muted text-center py-1"
        style={{ fontSize: 12 }}
      >
        Dapatkan informasi promo dan sebagainnya di
        {' '}
        <a
          href="https://mpstore.co.id"
          target="_blank"
          className="text-decoration-none text-warning font-weight-bold"
          rel="noreferrer"
        >
          mpstore.co.id
        </a>
      </div>
      <div
        className="container-fluid text-muted"
        style={{ backgroundColor: '#014998', height: 340 }}
      >
        <div className="container py-4">
          <div className="row">
            <div className="col-12 mb-4">
              <a
                href="https://mpstore.co.id"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require('../logomp-outline.png')}
                  height={60}
                  width="auto"
                  alt=""
                />
              </a>
            </div>
            <div className="d-md-block d-none" style={{ height: 40 }} />
            <div className="col-12 col-md-8">
              <p className="text-light mb-0">Dapatkan harga yang terbaik</p>
              <h3 className="text-light my-2">Daftar Menjadi Agen</h3>
              <p style={{ color: '#aaa' }}>
                Silahkan isi formulir dengan benar
              </p>
            </div>
            <div className="col-12 col-md-4 d-flex align-items-end">
              <a
                className="btn btn-outline-light mb-2"
                href="https://play.google.com/store/apps/details?id=id.co.aviana.m_pulsa&hl=in&gl=US"
                target="_blank"
                role="button"
                rel="noreferrer"
              >
                Download MPStore
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="d-block d-sm-none" style={{ height: 40 }} />

      <div
        className="container py-4 bg-light shadow rounded mb-4 content"
        style={{ marginTop: -40 }}
      >
        <div className="row mx-1 mx-md-2">
          <div className="col-12" style={{ marginTop: -60 }}>
            {/* <div className="btn-group" role="group" aria-label="Basic example">
              <button type="button" className="btn btn-secondary">Formulir</button>
              <button type="button" className="btn btn-secondary">Lihat Detail Member</button>
            </div> */}
            <Tabs defaultIndex={0} selectedTabClassName="active">
              <TabList className="list-unstyled">
                <Tab className="btn tab">Detail Agen</Tab>
                <Tab className="btn tab">Cek Harga</Tab>
                <Tab className="btn tab">Formulir</Tab>
              </TabList>

              <TabPanel>
                <DetailUpline
                  nama={dataUpline.nama}
                  namaToko={dataUpline.namatoko}
                  nomorTelepon={dataUpline.phone}
                  alamat={dataUpline.alamat}
                />
              </TabPanel>

              <TabPanel>
                <ListHarga pulsa={pulsa} paketData={paketData} game={game} topupSaldo={topupSaldo} ppob={ppob} tokenPLN={tokenPln} />
              </TabPanel>

              <TabPanel>
                <div className="needs-validation">
                  <div className="row g-3">
                    <div className="col-sm-12">
                      <label
                        htmlFor="namaLengkap"
                        className="form-label text-muted"
                        style={{ fontSize: '0.8em' }}
                      >
                        Nama Lengkap
                      </label>
                      <input
                        type="text"
                        className={
                          errors.namaLengkap
                            ? 'form-control is-invalid'
                            : 'form-control border-0 shadow-sm'
                        }
                        id="namaLengkap"
                        placeholder="Masukkan nama lengkap"
                        autoComplete="none"
                        ref={(input) => inputRef.namaLengkap = input}
                        value={namaLengkap}
                        onChange={(e) => setNamaLengkap(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        {errors.namaLengkap && errors.namaLengkap}
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <label
                        htmlFor="namaToko"
                        className="form-label text-muted"
                        style={{ fontSize: '0.8em' }}
                      >
                        Nama Toko
                      </label>
                      <input
                        type="text"
                        className={
                          errors.namaToko
                            ? 'form-control is-invalid'
                            : 'form-control border-0 shadow-sm'
                        }
                        id="namaToko"
                        placeholder="Masukkan nama toko anda"
                        autoComplete="none"
                        value={namaToko}
                        ref={(input) => inputRef.namaToko = input}
                        onChange={(e) => setNamaToko(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        {errors.namaToko && errors.namaToko}
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <label
                        htmlFor="nomerTelepon"
                        className="form-label text-muted"
                        style={{ fontSize: '0.8em' }}
                      >
                        Nomor Telepon
                      </label>
                      <input
                        type="tel"
                        className={
                          errors.nomorTelepon
                            ? 'form-control is-invalid'
                            : 'form-control border-0 shadow-sm'
                        }
                        id="nomerTelepon"
                        placeholder="Masukkan nomor telepon"
                        autoComplete="none"
                        data-inputmask="'alias': 'phonebe'"
                        value={nomorTelepon}
                        ref={(input) => inputRef.nomorTelepon = input}
                        onChange={(e) => setNomorTelepon(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        {errors.nomorTelepon && errors.nomorTelepon}
                      </div>
                    </div>

                    <div className="col-12">
                      <label
                        htmlFor="email"
                        className="form-label text-muted"
                        style={{ fontSize: '0.8em' }}
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className={
                          errors.email
                            ? 'form-control is-invalid'
                            : 'form-control border-0 shadow-sm'
                        }
                        id="email"
                        placeholder="Masukkan email"
                        value={email}
                        ref={(input) => inputRef.email = input}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        {errors.email && errors.email}
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6">
                      <label
                        htmlFor="provinsi"
                        className="form-label text-muted"
                        style={{ fontSize: '0.8em' }}
                      >
                        Provinsi
                      </label>
                      <select
                        className={
                          errors.provinsi
                            ? 'form-control is-invalid'
                            : 'form-control border-0 shadow-sm'
                        }
                        id="provinsi"
                        value={provinsi}
                        ref={(input) => inputRef.provinsi = input}
                        onChange={(e) => onChangeProvinsi(e)}
                      >
                        <option value="">Pilih...</option>
                        {listProvinsi.map((item) => (
                          <option key={item.id} value={item.name}>{item.name}</option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        {errors.provinsi && errors.provinsi}
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6">
                      <label
                        htmlFor="kota"
                        className="form-label text-muted"
                        style={{ fontSize: '0.8em' }}
                      >
                        Kota/Kabupaten
                      </label>
                      <select
                        className={
                          errors.kota
                            ? 'form-control is-invalid'
                            : 'form-control border-0 shadow-sm'
                        }
                        id="kota"
                        value={kota}
                        ref={(input) => inputRef.kota = input}
                        onChange={(e) => onChangeKota(e)}
                      >
                        <option value="">Pilih...</option>
                        {listKota.map((item) => (
                          <option key={item.id} value={item.name}>{item.name}</option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        {errors.kota && errors.kota}
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6">
                      <label
                        htmlFor="kecamatan"
                        className="form-label text-muted"
                        style={{ fontSize: '0.8em' }}
                      >
                        Kecamatan
                      </label>
                      <select
                        className={
                          errors.kecamatan
                            ? 'form-control is-invalid'
                            : 'form-control border-0 shadow-sm'
                        }
                        id="kecamatan"
                        value={kecamatan}
                        ref={(input) => inputRef.kecamatan = input}
                        onChange={(e) => setKecamatan(e.target.value)}
                      >
                        <option value="">Pilih...</option>
                        {listKecamatan.map((item) => (
                          <option key={item.id} value={item.name}>{item.name}</option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        {errors.kecamatan && errors.kecamatan}
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6">
                      <label
                        htmlFor="kodePos"
                        className="form-label text-muted"
                        style={{ fontSize: '0.8em' }}
                      >
                        Kode Pos
                      </label>
                      <input
                        type="number"
                        className={
                          errors.kodePos
                            ? 'form-control is-invalid'
                            : 'form-control border-0 shadow-sm'
                        }
                        id="kodePos"
                        placeholder="Masukkan kode pos"
                        value={kodePos}
                        ref={(input) => inputRef.kodePos = input}
                        onChange={(e) => setKodePos(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        {errors.kodePos && errors.kodePos}
                      </div>
                    </div>

                    <div className="col-12">
                      <label
                        htmlFor="alamat"
                        className="form-label text-muted"
                        style={{ fontSize: '0.8em' }}
                      >
                        Alamat
                      </label>
                      <textarea
                        className={
                          errors.alamat
                            ? 'form-control is-invalid'
                            : 'form-control border-0 shadow-sm'
                        }
                        id="alamat"
                        cols={3}
                        value={alamat}
                        placeholder="Masukkan alamat anda"
                        style={{ resize: 'none' }}
                        ref={(input) => inputRef.alamat = input}
                        onChange={(e) => setAlamat(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        {errors.alamat && errors.alamat}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-success px-5 my-4"
                      onClick={() => validation()}
                    >
                      Daftar
                    </button>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6">
            <img
              src={require('../logo-grey.png')}
              style={{ opacity: 0.5 }}
              className="my-3"
              height={30}
              width="auto"
              alt=""
            />
            <p style={{ fontSize: 12, color: '#bbb' }}>
              Melalui Aplikasi MPStore, Semua Orang Dapat Melakukan Transaksi
              Dan Pembayaran Secara Online Dengan Mudah, Nyaman, Di Mana Pun Dan
              Kapan Pun Hanya Dengan Satu Aplikasi.
            </p>
            <p style={{ fontSize: 12, color: '#bbb' }}>&copy; 2022 MPStore</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
