/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import axios from 'axios';

export async function getToken(ref) {
  const res = await axios({
    method: 'get',
    url: `api/ref/${ref}?${new Date().getTime()}`,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then((response) => response.data)
    .catch((error) => error.response.data);
  return res;
}

export async function ping() {
  const res = await axios({
    method: 'get',
    url: '/ping',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then((response) => response)
    .catch((error) => error.response.data);
}

export async function getApiWithKey(url, method, token) {
  const res = await axios({
    method,
    url: `api/${url}?${new Date().getTime()}`,
    headers: {
      'mpx-token': token,
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then((response) => response.data)
    .catch((error) => error.response.data);
  return res;
}

// eslint-disable-next-line consistent-return
export async function errorHandler(error) {
  if (error.statusCode !== undefined) {
    if (error.statusCode === 500) {
      return { show: true, code: error.statusCode, message: 'Internal Server Error' };
    } else {
      return { show: true, code: error.statusCode, message: error.message };
    }
  } else {
    return { show: true, code: error.status, message: error.values.message };
  }
}
