/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';

export function DetailUpline(props) {
  return (
    <div className="">
      <div className="d-flex align-items-center mb-3">
        <div className="text-center">
          <i
            className="fa fa-id-card-o fa-2x"
            style={{
              width: 60,
              color: '#555',
            }}
            aria-hidden="true"
          />
        </div>
        <div>
          <h6 className="mb-0">{props.nama}</h6>
          <span style={{ fontSize: 12, color: '#555' }}>Nama</span>
        </div>
      </div>
      <div className="d-flex align-items-center mb-3">
        <div className="text-center">
          <i
            className="fa fa-shopping-basket fa-2x"
            style={{
              width: 60,
              color: '#555',
            }}
            aria-hidden="true"
          />
        </div>
        <div>
          <h6 className="mb-0">{props.namaToko}</h6>
          <span style={{ fontSize: 12, color: '#555' }}>Nama Toko</span>
        </div>
      </div>
      <div className="d-flex align-items-center mb-3">
        <div className="text-center">
          <i
            className="fa fa-phone fa-2x"
            style={{
              width: 60,
              color: '#555',
            }}
            aria-hidden="true"
          />
        </div>
        <div>
          <h6 className="mb-0">{props.nomorTelepon}</h6>
          <span style={{ fontSize: 12, color: '#555' }}>Telepon</span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="text-center">
          <i
            className="fa fa-map-marker fa-2x"
            style={{
              width: 60,
              color: '#555',
            }}
            aria-hidden="true"
          />
        </div>
        <div>
          <h6>{props.alamat}</h6>
          <span style={{ fontSize: 12, color: '#555' }}>Alamat</span>
        </div>
      </div>
    </div>
  );
}
