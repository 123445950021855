/* eslint-disable no-lone-blocks */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Tab from 'react-tabs/lib/components/Tab';
import TabList from 'react-tabs/lib/components/TabList';
import TabPanel from 'react-tabs/lib/components/TabPanel';
import Tabs from 'react-tabs/lib/components/Tabs';

// buka ini buat scroll https://stackoverflow.com/questions/40926181/react-scrolling-a-div-by-dragging-the-mouse

function TabItem(props) {
  return (
    <AccordionItem className="bg-white p-3 rounded shadow-sm mb-2">
      <AccordionItemHeading className="">
        <AccordionItemButton className="h6 mb-0 py-2 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={props.item.logooperator}
              alt=""
              className="mx-2 align-self-center"
              width={40}
            />
            <span>{props.item.namaoperator}</span>
          </div>
          <i className="fa fa-chevron-right" />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {/* <hr className="mt-2" /> */}
        <table className="table table-sm align-middle mb-0">
          {/* <thead className="border-0">
        <tr>
        <th>#</th>
        <th>Jenis</th>
        <th>Status</th>
        <th>Harga</th>
        </tr>
        </thead> */}
          <tbody>
            {!props.item.product && (
              <tr>
                <th
                  scope="row"
                  className="py-3"
                  colSpan={4}
                  style={{
                    fontSize: '0.8em',
                    textAlign: 'center',
                  }}
                >
                  Tidak Ada Data
                </th>
              </tr>
            )}
            {props.item.product && (
              <>
                {props.item.product.map((sub) => {
                  const gangguanColor = sub.gangguan.toLowerCase() === 'tidak'
                    ? 'bg-success'
                    : 'bg-warning';
                  return (
                    <tr key={sub.idproduct}>
                      <th
                        scope="row"
                        className="py-3"
                        style={{
                          fontSize: '0.8em',
                        }}
                      >
                        {sub.kodeproduk}
                      </th>
                      <td className="py-3">{sub.namaproduk}</td>
                      <td className="py-3">
                        <span
                          className={`${gangguanColor} text-white py-1 px-2 rounded-pill`}
                          style={{
                            fontSize: '0.8em',
                          }}
                        >
                          {sub.gangguan.toLowerCase() === 'tidak'
                            ? 'Tersedia'
                            : 'Gangguan'}
                        </span>
                      </td>
                      <td className="py-3">
                        Rp
                        {sub.harga}
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </AccordionItemPanel>
    </AccordionItem>
  );
}

export function ListHarga(props) {
  const data = {
    pulsa: props.pulsa,
    paketData: props.paketData,
    game: props.game,
    topupSaldo: props.topupSaldo,
    ppob: props.ppob,
    tokenPLN: props.tokenPLN,
  };

  const kategoriHarga = [
    'Pulsa',
    'Paket Data',
    'Game',
    'Topup Saldo',
    'PPOB',
    'Token PLN',
  ];

  // useEffect(() => {
  //   // eslint-disable-next-line prefer-const
  //   let dataTemp = {
  //     pulsa: [],
  //     paketData: [],
  //     game: [],
  //     topupSaldo: [],
  //     ppob: [],
  //     tokenPLN: [],
  //   };

  //   props.listHarga.map((item) => {
  //     if (item.kategori.toLowerCase() === 'pulsa') {
  //       dataTemp.pulsa.push(item);
  //     } else if (item.kategori.toLowerCase() === 'paket data') {
  //       dataTemp.paketData.push(item);
  //     } else if (item.kategori.toLowerCase() === 'game') {
  //       dataTemp.game.push(item);
  //     } else if (item.kategori.toLowerCase() === 'topup saldo') {
  //       dataTemp.topupSaldo.push(item);
  //     } else if (item.kategori.toLowerCase() === 'ppob') {
  //       dataTemp.ppob.push(item);
  //     } else if (item.kategori.toLowerCase() === 'token pln') {
  //       dataTemp.tokenPLN.push(item);
  //     }

  //     return true;
  //   });

  //   setData(dataTemp);
  // }, [props.listHarga]);

  return (
    <Tabs defaultIndex={0} selectedTabClassName="active">
      <TabList
        className="list-unstyled d-block hide-scrollbar"
        style={{
          overflowX: 'auto',
          scrollbars: 'none',
          overflowY: 'hidden',
          whiteSpace: 'nowrap',
          height: 40,
        }}
      >
        {kategoriHarga.map((item) => (
          <Tab
            key={item}
            className="btn btn-outline-success btn-sm rounded-pill mx-1"
          >
            {item}
          </Tab>
        ))}
      </TabList>

      <TabPanel>
        <Accordion allowZeroExpanded>
          {data.pulsa.map((item) => (
            <TabItem key={item.namaoperator} item={item} />
          ))}
        </Accordion>
      </TabPanel>

      <TabPanel>
        <Accordion allowZeroExpanded>
          {data.paketData.map((item) => (
            <TabItem key={item.namaoperator} item={item} />
          ))}
        </Accordion>
      </TabPanel>

      <TabPanel>
        <Accordion allowZeroExpanded>
          {data.game.map((item) => (
            <TabItem key={item.namaoperator} item={item} />
          ))}
        </Accordion>
      </TabPanel>

      <TabPanel>
        <Accordion allowZeroExpanded>
          {data.topupSaldo.map((item) => (
            <TabItem key={item.namaoperator} item={item} />
          ))}
        </Accordion>
      </TabPanel>

      <TabPanel>
        <Accordion allowZeroExpanded>
          {data.ppob.map((item) => (
            <TabItem key={item.namaoperator} item={item} />
          ))}
        </Accordion>
      </TabPanel>

      <TabPanel>
        <Accordion allowZeroExpanded>
          {data.tokenPLN.map((item) => (
            <TabItem key={item.namaoperator} item={item} />
          ))}
        </Accordion>
      </TabPanel>

    </Tabs>
  );
}
